export const restaurantsData = [
    {
        "name": "Saigon Social",
        "position": [40.72173904219664, -73.98839870602329],
        "description": "Saigon Social on the Lower East Side has some top tier Vietnamese food. It offers the perfect restaurant experience and you will inevitably become attached to their great tasting food!",
        "cuisine": "Vietnamese",
        "icon": "location-icon4",
        "class": "",
        "borough": "Manhattan",
        "image": "Saigon",
        "campaign": "cmp_c641f439dd84",
        "slides": [require("../assets/restaurants/SaigonSocial-b-food.jpg"),
            require("../assets/restaurants/SaigonSocial-b-rest.jpeg"),
            require("../assets/restaurants/SaigonSocial-b copy.png")],
        "owner": "Chef Helen Nguyen",
        "addressL1": "172 Orchard St,",
        "addressL2": "New York, NY 10002",
        "est": "2019",
        "quote": "I feel community is the foundation of my being",
        "q1": "Why do you do what you do? How did you get here?",
        "fulldescription1": "I'm the happiest when I'm able to serve and cook for people, it brings me so much joy and gratification. Becoming a chef was a career change much later in life. New York was supposed to be just a one year sabbatical, then I fell in love - with the city, the people, the industry. I saw a resurgence and re-introduction if you will happening with Vietnamese cuisine here and wanted to contribute and be a part of the growth. ",
        "q2": "What does community mean to you?",
        "fulldescription2": "I feel community is the foundation of my being. It's a collective of friends, family, neighbors, restaurants, cafes, businesses that make up the fabric of our every day life. I think it's so important to nurture, support and have meaningful relationships with every tangible and intangibles that make our communities so unique and special.  ",
        "q3": "Can you name a memory or moment of yours when food helped uplift your day?",
        "fulldescription3": "When I lived in Seattle, I volunteered at my church kitchen every Thursday and Friday. There were days I was coming in from extremely stressful work days. Being in the kitchen with fellow volunteers, making and distributing meals for our community always uplifted me and put things in perspective for me. Realizing that \"stressful work days\" and the issues I thought were stressful were truly miniscule on the grand scheme of things... that there was such a large part of our community that were experiencing food insecurity and not having a place to call home - those were real problems.",
        "q4": "Why is it important for you to continue to make your restaurant a place that welcomes all? ",
        "fulldescription4": "Growing up my family struggled with poverty and food insecurity. I have fond memories of restaurants, organizations and small businesses that always extended their support, love and care to my family. As a child, I was grateful, but didn't understand the gravity of it, as I do now. I am still grateful and those hold those memories very dear to my heart. It is so important to me that I make sure that what ever it is I do, I am able to provide a place that is warm and community oriented. You never know what kind of impact you can make to a person or family, but you can start by creating a place that is welcoming to all.",
        "website": "https://www.saigonsocialnyc.com/",
        "instagram": "https://www.instagram.com/saigonsocialnyc",
        "facebook": "",
        "googleMapSRC": "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3023.843268896146!2d-73.9904371846713!3d40.72146664497343!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c259deafe476ab%3A0xd6283061f2d23a1!2sSaigon%20Social!5e0!3m2!1sen!2sus!4v1631223709691!5m2!1sen!2sus",
        "googleMapURL": "https://www.google.com/maps/place/Saigon+Social/@40.721463,-73.988248,15z/data=!4m5!3m4!1s0x0:0xd6283061f2d23a1!8m2!3d40.7215071!4d-73.988398?hl=en"
    },
    {
        "name": "New York Bo Ky",
        "position": [40.717306901522825, -73.9996705581586],
        "description": "Chinatown establishments like New York Bo Ky are what makes the spirit of our neighborhood. Serving a unique cuisine of Teochew, as all food served is delicious!",
        "cuisine": "Chinese",
        "icon": "location-icon4",
        "class": "",
        "borough": "Manhattan",
        "image": "Bo Ky",
        "campaign": "cmp_142743a7b1d7",
        "slides": [require("../assets/restaurants/BoKy-b1.png")],
        "owner": "",
        "addressL1": "94 Baxter Street,",
        "addressL2": "New York, NY 10013",
        "est": "",
        "quote": "",
        "q1": "Why do you do what you do? How did you get here?",
        "fulldescription1": "",
        "q2": "What does community mean to you?",
        "fulldescription2": "",
        "q3": "Can you name a memory or moment of yours when food helped uplift your day?",
        "fulldescription3": "",
        "q4": "Why is it important for you to continue to make your restaurant a place that welcomes all? ",
        "fulldescription4": "",
        "website": "http://www.bo-ky-nyc.com/",
        "instagram": "",
        "facebook": "",
        "googleMapSRC": "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12096.171780011498!2d-73.9997242!3d40.7170711!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xeb2d6d9efbad813!2sNew%20York%20Bo%20Ky!5e0!3m2!1sen!2sus!4v1631814378890!5m2!1sen!2sus",
        "googleMapURL": "https://www.google.com/maps/place/New+York+Bo+Ky/@40.7170711,-73.9997242,15z/data=!4m5!3m4!1s0x0:0xeb2d6d9efbad813!8m2!3d40.7170836!4d-73.9997541 "
    },
    {
        "name": "Collective Food Works",
        "position": [40.66954456980341, -73.90777439701911],
        "description": "",
        "cuisine": "Southern & African Diaspora",
        "icon": "location-icon4",
        "class": "",
        "borough": "Brooklyn",
        "image": "Collective",
        "campaign": "cmp_efe2f96f9cca",
        "slides": [require("../assets/restaurants/Collective Fare-b1.jpeg"),
            require("../assets/restaurants/Collective Fare-b2.jpeg"),],
        "owner": "",
        "addressL1": "69 Belmont Avenue",
        "addressL2": "Brooklyn, NY 11212",
        "est": "",
        "quote": "",
        "q1": "Why do you do what you do? How did you get here?",
        "fulldescription1": "",
        "q2": "What does community mean to you?",
        "fulldescription2": "",
        "q3": "Can you name a memory or moment of yours when food helped uplift your day?",
        "fulldescription3": "",
        "q4": "Why is it important for you to continue to make your restaurant a place that welcomes all? ",
        "fulldescription4": "",
        "website": "https://www.collectivefare.com/",
        "instagram": "https://www.instagram.com/collectivefare",
        "facebook": "https://www.facebook.com/collectivefare",
        "googleMapSRC": "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12104.905289416452!2d-73.9078284!3d40.668982!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xe9e041d0644a38b5!2sCollective%20Fare%20%2B%20Collective%20Food%20Works%20Inc.!5e0!3m2!1sen!2sus!4v1631797856220!5m2!1sen!2sus",
        "googleMapURL": "https://www.google.com/maps/place/Collective+Fare+%2B+Collective+Food+Works+Inc./@40.668982,-73.9078284,15z/data=!4m2!3m1!1s0x0:0xe9e041d0644a38b5?sa=X&ved=2ahUKEwigmpG1yIPzAhXGnOAKHSzJDsUQ_BJ6BAhgEAU "
    },
    {
        "name": "Eat Gai",
        "position": [40.71822443487061, -73.98825714650852],
        "description": "",
        "cuisine": "Thai",
        "icon": "location-icon4",
        "class": "",
        "borough": "Manhattan",
        "image": "eat-gai",
        "campaign": "cmp_8e97a255da85",
        "slides": [require("../assets/restaurants/EatGai-b3.jpeg"),
            require("../assets/restaurants/EatGai-b1.jpeg"),
            require("../assets/restaurants/EatGai-b2.jpeg")
        ],
        "owner": "Bryan Chunton",
        "addressL1": "88 Essex Street Booth 46,",
        "addressL2": "New York, NY 10002",
        "est": "",
        "quote": "Community means family. It is a place where we all get together and support each other.",
        "q1": "Why do you do what you do? How did you get here?",
        "fulldescription1": "I was born in it. It has become a passion for me. It is part of my  life. I got to where I am because  of my family's love for food and hospitality.",
        "q2": "What does community mean to you?",
        "fulldescription2": "Community means family. It is a place where we all get together and support each other.",
        "q3": "Can you name a memory or moment of yours when food helped uplift your day?",
        "fulldescription3": "Every now and then I eat a plate of chicken and rice, it reminds me of when my mom used to make it for me.",
        "q4": "Why is it important for you to continue to make your restaurant a place that welcomes all? ",
        "fulldescription4": "It is important because it will remind me that I am part of the community and part of the family.",
        "website": "",
        "instagram": "https://www.instagram.com/eat_gai",
        "facebook": "https://www.facebook.com/eatgai",
        "googleMapSRC": "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12096.002107303975!2d-73.9882035!3d40.7180049!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x634ae975ad7496a!2sEat%20Gai!5e0!3m2!1sen!2sus!4v1631796862688!5m2!1sen!2sus",
        "googleMapURL": "https://www.google.com/maps/place/Eat+Gai/@40.7180049,-73.9882035,15z/data=!4m2!3m1!1s0x0:0x634ae975ad7496a?sa=X&ved=2ahUKEwi7o9fUxIPzAhWpm-AKHc4yB6MQ_BJ6BAhwEAU"
    },
    {
        "name": "Bánh",
        "position": [40.800989481184466, -73.96566687339403],
        "description": "",
        "cuisine": "Vietnamese",
        "icon": "location-icon4",
        "class": "",
        "borough": "Manhattan",
        "image": "banh",
        "campaign": "cmp_0d95d3a79473",
        "slides": [require("../assets/restaurants/banh-b1.png"),
            require("../assets/restaurants/banh-b2.png"),
            require("../assets/restaurants/banhb3.png")],
        "owner": "",
        "addressL1": "",
        "addressL2": "",
        "est": "",
        "quote": "",
        "q1": "Why do you do what you do? How did you get here?",
        "fulldescription1": "",
        "q2": "What does community mean to you?",
        "fulldescription2": "",
        "q3": "Can you name a memory or moment of yours when food helped uplift your day?",
        "fulldescription3": "",
        "q4": "Why is it important for you to continue to make your restaurant a place that welcomes all? ",
        "fulldescription4": "",
        "website": "",
        "instagram": "",
        "facebook": "",
        "googleMapSRC": "",
        "googleMapURL": "https://www.google.com/search?q=banh%20restaurant&oq=b&aqs=chrome.1.69i60j69i59l2j69i60l3j69i61j69i65.2734j0j7&sourceid=chrome&ie=UTF-8&tbs=lf:1,lf_ui:9&tbm=lcl&sxsrf=AOaemvLv3y19LD--UFGJvTDsSn63ZrvuJA:1635813027913&rflfq=1&num=10&rldimm=9195422918215459343&lqi=Cg9iYW5oIHJlc3RhdXJhbnRI5O27oteygIAIWiUQABABGAAYASIPYmFuaCByZXN0YXVyYW50KgQIAhAAKgQIAxABkgEVdmlldG5hbWVzZV9yZXN0YXVyYW50mgEkQ2hkRFNVaE5NRzluUzBWSlEwRm5TVVEyYmpsSFoyZFJSUkFCqgEMEAEqCCIEZm9vZCgA&phdesc=68-Jh3O5XGs&ved=2ahUKEwjf-smztvjzAhUxneAKHfkCASQQvS56BAgLEFY&rlst=f#rlfi=hd:;si:9195422918215459343,l,Cg9iYW5oIHJlc3RhdXJhbnRI5O27oteygIAIWiUQABABGAAYASIPYmFuaCByZXN0YXVyYW50KgQIAhAAKgQIAxABkgEVdmlldG5hbWVzZV9yZXN0YXVyYW50mgEkQ2hkRFNVaE5NRzluUzBWSlEwRm5TVVEyYmpsSFoyZFJSUkFCqgEMEAEqCCIEZm9vZCgA,y,68-Jh3O5XGs;mv:[[40.81188590000001,-73.9535369],[40.6063639,-74.0155046]];tbs:lrf:!1m4!1u3!2m2!3m1!1e1!1m4!1u2!2m2!2m1!1e1!1m4!1u1!2m2!1m1!1e1!1m4!1u1!2m2!1m1!1e2!1m4!1u22!2m2!21m1!1e1!2m1!1e2!2m1!1e1!2m1!1e3!3sIAE,lf:1,lf_ui:9"
    },
    {
        "name": "Mee Sum Cafe",
        "position": [40.71514856398871, -73.99834291757494],
        "description": "",
        "cuisine": "",
        "icon": "location-icon4",
        "class": "",
        "borough": "Manhattan",
        "image": "mee-sum",
        "campaign": "cmp_0fef2ae4507b",
        "slides": [require("../assets/restaurants/meeSum-b1.png")],
        "owner": "",
        "addressL1": "26 Pell Street,",
        "addressL2": "New York, NY 10013",
        "est": "",
        "quote": "",
        "q1": "Why do you do what you do? How did you get here?",
        "fulldescription1": "",
        "q2": "What does community mean to you?",
        "fulldescription2": "",
        "q3": "Can you name a memory or moment of yours when food helped uplift your day?",
        "fulldescription3": "",
        "q4": "Why is it important for you to continue to make your restaurant a place that welcomes all? ",
        "fulldescription4": "",
        "website": "",
        "instagram": "",
        "facebook": "",
        "googleMapSRC": "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3024.134706910463!2d-74.000488684595!3d40.715050979331686!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c25a26fcee777d%3A0xf67cc4d99de229b3!2sMee%20Sum%20Cafe!5e0!3m2!1sen!2sus!4v1632945024563!5m2!1sen!2sus",
        "googleMapURL": "https://www.google.com/maps/place/Mee+Sum+Cafe/@40.715051,-74.0004887,17z/data=!3m1!4b1!4m5!3m4!1s0x89c25a26fcee777d:0xf67cc4d99de229b3!8m2!3d40.7149518!4d-73.9983595"
    },
    {
        "name": "Soco",
        "position": [40.693918641684604, -73.96449900223305],
        "description": "",
        "cuisine": "Southern food",
        "icon": "location-icon4",
        "class": "",
        "borough": "Brooklyn",
        "image": "soco",
        "campaign": "cmp_676dadf94a30",
        "slides": [require("../assets/restaurants/SoCo-b1.jpeg")],
        "owner": "",
        "addressL1": "509 Myrtle Avenue,",
        "addressL2": "Brooklyn, NY 11205",
        "est": "",
        "quote": "",
        "q1": "Why do you do what you do? How did you get here?",
        "fulldescription1": "",
        "q2": "What does community mean to you?",
        "fulldescription2": "",
        "q3": "Can you name a memory or moment of yours when food helped uplift your day?",
        "fulldescription3": "",
        "q4": "Why is it important for you to continue to make your restaurant a place that welcomes all? ",
        "fulldescription4": "",
        "website": "http://www.socobklyn.com",
        "instagram": "https://www.instagram.com/socobk/",
        "facebook": "https://www.facebook.com/socobk",
        "googleMapSRC": "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3025.102521791577!2d-73.96668768459557!3d40.69373967933398!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c25bc0bac3afb5%3A0xe85b6f7a9ac10f72!2sSoco!5e0!3m2!1sen!2sus!4v1633028971614!5m2!1sen!2sus",
        "googleMapURL": "https://www.google.com/maps/place/Soco/@40.6937397,-73.9666877,17z/data=!3m1!4b1!4m5!3m4!1s0x89c25bc0bac3afb5:0xe85b6f7a9ac10f72!8m2!3d40.6937417!4d-73.9645002"
    },
    {
        "name": "New Amsterdam Pasta",
        "position": [40.718154881464514, -73.98813635815863],
        "description": "",
        "cuisine": "Amsterdam",
        "icon": "location-icon4",
        "class": "",
        "borough": "Manhattan",
        "image": "amsterdam",
        "campaign": "cmp_e297cc858ae6",
        "slides": [require("../assets/restaurants/NewAmsterdamPasta-b3.jpeg"),
            require("../assets/restaurants/NewAmsterdamPasta-b1.jpeg"),
            require("../assets/restaurants/NewAmsterdamPasta-b2.jpeg")
        ],
        "owner": "Michael Brafman",
        "addressL1": "88 Essex Street Booth 46,",
        "addressL2": "New York, NY 10002",
        "est": "",
        "quote": "We are ALL part of the community, not just the good parts or the fun things.",
        "q1": "Why do you do what you do? How did you get here?",
        "fulldescription1": "We got here after years of loving food and working for other people. The time was right to pave our own path.",
        "q2": "What does community mean to you?",
        "fulldescription2": "Community is bigger than us. We are a small part of something so much bigger and better for that matter. We can’t exist without community and community can’t exist without all the people and businesses in it.",
        "q3": "Can you name a memory or moment of yours when food helped uplift your day?",
        "fulldescription3": "Ha. Everyday. That’s why we are in the food industry.",
        "q4": "Why is it important for you to continue to make your restaurant a place that welcomes all? ",
        "fulldescription4": "Again back to the community. We are ALL part of the community, not just the good parts or the fun things.",
        "website": "",
        "instagram": "https://www.instagram.com/newamsterdampasta",
        "facebook": "https://www.facebook.com/newamsterdampasta",
        "googleMapSRC": "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12095.959243450463!2d-73.988107!3d40.7182408!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xb11b6f1e80c55d4d!2sNew%20Amsterdam%20Pasta!5e0!3m2!1sen!2sus!4v1631796348394!5m2!1sen!2sus",
        "googleMapURL": "https://www.google.com/maps/place/New+Amsterdam+Pasta/@40.7182408,-73.988107,15z/data=!4m5!3m4!1s0x0:0xb11b6f1e80c55d4d!8m2!3d40.7182608!4d-73.9881747"
    }
]
